.SurveyHeader {
    border-collapse: collapse;
    border-width: thin;
    border-style: solid;
    border-color: transparent;
    padding: 10px 0px 10px 0px;
}

.survey-header-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left:10px;
    margin-right:10px;
    margin-top:10px;
    margin-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.survey-header-description-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left:10px;
    margin-right:10px;
    margin-top:10px;
    margin-bottom:10px;
    padding-left:10px;
    padding-right:10px;
}

.survey-perioid-input-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: fit-content;
}

.survey-perioid-input-container .react-datepicker-wrapper {
    margin: 0 0;
}

.survey-perioid-input-container .react-datepicker-wrapper input {
    border-radius: 8px;
    border: solid 1px;
    padding: 4px 12px;
    border-color: #d2d7df;
    text-align: center;
}

/* date-picker Input 아이콘 넣기 style
.survey-perioid-input-container .react-datepicker__input-container::before {
    content: '\e99c';
    display: inline-block;
    position: absolute;
    top : 7px;
    left : 7px;
    font-family: icomoon !important;
    font-size: 1rem;
    color: #333;
}
*/

.Survey-Header-Title {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px transparent;
    background-color: transparent;
    font-size: large;
    font-weight: bold;
    width: 95%;
    box-sizing: border-box;
}

.Survey-Header-Title::placeholder {
    color:lightgray;
}

.Survey-Header-Title:focus {
    margin-bottom: 5px;
    border-bottom: solid 2px #ec9a5f;
    font-size: large;
    font-weight: bold;
    width: 95%;
    box-sizing: border-box;
}

.Survey-Header-Description {
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px transparent;
    background-color: transparent;
    width: 95%;
    box-sizing: border-box;
}

.Survey-Header-Description::placeholder {
    color:lightgray;
}

.Survey-Header-Description:focus {
    outline: none;
    border-bottom: solid 2px #ec9a5f;
    width: 95%;
    box-sizing: border-box;
}

/*
.survey-perioid-input-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.survey-perioid-input-container .react-datepicker-wrapper {
    margin: 12px 0;
    width: 100%;
}

.survey-perioid-input-container .react-datepicker-wrapper input {
    padding: 4px 12px;
}
*/