.survey_card_body {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.312rem;
  margin-bottom: 0.512rem;
}

.survey_card_list_none {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 0.312rem;
  margin-bottom: 0.512rem;
  padding: 20px;
  width:100%;
  text-align: center;
}