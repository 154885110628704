.ComponentItem {
    border-radius: 15px 15px;
    background-color: white;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px transparent;
    box-shadow: 1px 1px 1px 1px;
}

.ComponentItem:hover {
    border-radius: 15px 15px;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px darkgray;
    box-shadow: 1px 1px 1px 1px dimgray;
}

.ComponentItem:active {
    border-radius: 15px 15px;
    margin: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    border: solid 1px dimgrey;
    background-color: darkgray;
    color: white;
    box-shadow: 1px 1px 1px 1px darkgray;
}