.app-content-header {
  /* font-family: Arial, sans-serif; */
  padding: 0px 0px 0px 50px;
}
.headapp-content-headerer .title {
  font-size: 18px;
  font-weight: bolder;
}
.app-content-header .app-content {
  font-size: 13px;
  text-align: left;
}

.short-description {
  /* font-family: Arial, sans-serif; */
}
.title {
  font-weight: bolder;
}
.short-description .comment {
  font-size: 14px;
  /* margin: 0px 0px -10px 0px; line-height: 1%; */
}

.slick-prev:before {
  content: "〈";
  font-weight: bolder;
  color: #303030;
  font-size: 30px;
  margin-left: -20px;
}
.slick-next:before {
  content: "〉";
  font-weight: bolder;
  color: #303030;
  font-size: 30px;
  padding-left: 20px;
}

.slick-list {margin: 0 -10px;}
.slick-slide>div {padding: 0 10px;}

.description {
  /* font-family: Arial, sans-serif; */
  /* padding: 0px 30px 0px 30px; */
}
.description .image {
  width: auto;
  height: 350px;
  max-height: 350px;
  /* border: 1px solid #e0e0e0; */
  margin: 0px;
  padding: 0px;
  object-fit: cover;
  border-radius: 10px;
}

.description .banner {
  width: 350px;
  height: 350px;
  /* border: 1px solid #e0e0e0; */
  margin: 0px;
  padding: 0px;
  vertical-align: middle;
  object-fit: contain;
  border-radius: 10px;
}

.description .comment {
  /* font-size: 14px; */
  /* margin: 0px 0px -10px 0px; line-height: 1%; */
}

.whats-new {
  /* font-family: Arial, sans-serif; */
  padding: 0px 30px 0px 30px;
}
.whats-new .title {
  font-weight: bolder;
}
.whats-new .comment {
  font-size: 14px;
}

.admin-comment {
  font-family: Arial, sans-serif;
  padding: 0px 30px 0px 30px;
}
.admin-comment .title {
  font-weight: bolder;
}
.admin-comment .comment {
  font-size: 14px;
}



.icon-area {
  width: 200px;
  padding: 5px 0px 0px 50px;
}

.icon-area .app-icon {
  border: 1px solid #e0e0e0;
}


.detail-button {
  width: 120px;
}