.SurveyBorder {
    border-radius: 8px 8px;
    border-collapse: collapse;
    border-width: thin;
    border-style: solid;
    border-color: transparent;
    background-color: rgb(243, 243, 243);
    margin: 10px 0px 10px 0px;
    padding: 10px;
    width: 100%;
    box-shadow: 3px 3px 3px 3px lightgray;
}

.SurveyBorder:hover {
    border-radius: 8px 8px;
    border-collapse: collapse;
    border-width: thin;
    border-style: solid;
    background-color: rgb(247, 247, 247);
    margin: 10px 0px 10px 0px;
    padding: 10px;
    width:100%;
    box-shadow: 3px 3px 3px 3px rgb(138, 142, 164);
}

.Survey-Question {
    margin-bottom: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px transparent;
    background-color: transparent;
    font-size:medium;
    font-weight: bold;
    width: 98%;
    box-sizing: border-box;
}

.Survey-Question::placeholder {
    color:lightgray;
}

.Survey-Question:focus {
    outline: none;
    margin-bottom: 5px;
    border-bottom: solid 2px #ec9a5f;
    font-size:medium;
    font-weight: bold;
    width: 98%;
    box-sizing: border-box;
}

.Survey-Description {
    margin-bottom: 5px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 2px transparent;
    background-color: transparent;
    width: 98%;
    box-sizing: border-box;
}

.Survey-Description::placeholder {
    color:lightgray;
}

.Survey-Description:focus {
    outline: none;
    margin-bottom: 5px;
    border-bottom: solid 2px #ec9a5f;
    width: 98%;
    box-sizing: border-box;
}

.Survey-Candidate {
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px transparent;
    background-color: transparent;
    margin-top: 3px;
    margin-bottom: 3px;
}

.Survey-Candidate:focus {
    outline: none;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px #ec9a5f;
    background-color: transparent;
    margin-top: 3px;
    margin-bottom: 3px;
}

.Survey-Candidate-Rating {
    margin-top: 3px;
    margin-bottom: 3px;
}

.Survey-Comment {
    margin-top:10px;
    margin-bottom: 5px;
    color: rgb(109, 112,114);
    width: 100%;
    box-sizing: border-box;
}

.Survey-Comment:focus {
    margin-top:10px;
    margin-bottom: 5px;
    color: rgb(109, 112,114);
    width: 100%;
    box-sizing: border-box;
}
