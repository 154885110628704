/* Actual input */

.input-range-number {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 30px;
    background-color: dimgray;
    overflow: hidden;
    border-radius: 15px;
    margin: 2px;
    }

.input-range-number span {
    position: absolute;
    left: 50%;
    margin-left: -15px;
    display: inline-block;
    background-color: white;
    height: 100%;
    width: 30px;
    border-radius: 15px;
    text-align: center;
    line-height: 30px;
    font-size: 16px;
    color: #4a5073;
    letter-spacing: -1px;
}

.input-range-number button {
    display: inline-block;
    width: 40px;
    height: 100%;
    border: none;
    color: white;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    background-color: #4a5073;
    transition: background-color .2s ease;
}

.input-range-number button:nth-of-type(1) {
    padding-right: 20px
}

.input-range-number button:nth-of-type(2) {
    padding-left: 20px;
}

.input-range-number button:hover {
    background-color: rgb(138, 142, 164);
}


/*
.input-number {
    position: relative;
    width: 100px;
    height: 40px;
    background-color: dimgray;
    overflow: hidden;
    border-radius: 20px;
    margin: 2px;
    }

.input-number span {
    position: absolute;
    left: 50%;
    margin-left: -20px;
    display: inline-block;
    background-color: white;
    height: 100%;
    width: 40px;
    border-radius: 20px;
    text-align: center;
    line-height: 40px;
    font-size: 16px;
    color: dimgray;
    letter-spacing: -1px;
}

.input-number button {
    display: inline-block;
    -: 50px;
    height: 100%;
    background-color: dimgray;
    border: none;
    color: white;
    font-size: 20px;
    cursor: pointer;
    background-color: rgba(255,255,255,.2);
    transition: background-color .2s ease;
}

.input-number button:nth-of-type(1) {
    padding-right: 20px
}

.input-number button:nth-of-type(2) {
    padding-left: 20px;
}

.input-number button:hover {
    background-color: darkgray;
}
*/