#register-edit {
  font-family: Arial, sans-serif;
}

.upload-box {
  color: #c0c0c0;
  margin-top: 10px;
}

.upload-files {
  border-style: solid;
  border-width: thin;
  border-color: #d1d7E0;
  margin: 10px 0px 10px 0px;
  border-radius: 15px;
  padding: 10px;
}
.upload-files .title {
  font-size: 1rem;
  display: block;
  text-overflow:ellipsis;
  overflow: hidden;
  white-space:nowrap;

  padding-top: 10px;
}

#app-trash {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 1180px;
  padding: 0 10px;
  overflow: auto;
}
#app-trash a {
  height: 42px;
  width: 42px;
  border-radius: 50%;
  text-align: center;
  line-height: 35px;
  font-size: 17px;
  color: coral;
  background: white;
  margin: 0 4px;
  border: 2px solid coral;
  transition: all 0.3s ease;
}
#app-trash a:hover{
  color: white;
  background-color: coral;
}

#icon-img-wrapper {
  width: auto;
  height: 170px;
  position:relative;
  cursor:pointer;
  display: inline-flex;
  margin: -0.5rem 0rem 1.5rem 1.5rem;
}
#icon-img-wrapper> div {
  position:absolute;
  top: 100%;
  left: 63%;
  background:rgb(226, 95, 95);
  width:55px;
  height:55px;
  border-radius:50%;
  text-align: center;
  padding-top: 9px;
  margin-top: -63px;
  opacity:0;
  transform:scale(1);
  transition:all .5s linear;
  font-size:1.5em;
}
#icon-img-wrapper > img {
  box-shadow: 0 1px 10px rgba(0,0,0,0.4);
  /* width:inherit;
  height:inherit; */
  object-fit: cover;
  border-radius: 15px;
}
#icon-img-wrapper:hover {
  background: #c0c0c0;
  border-radius: 15px;
  opacity: 0.6;
}
#icon-img-wrapper:hover > div {
  opacity:1;
  transform:scale(1);
  color: white;
}

#banner-img-wrapper {
  width: 348px;
  height: 170px;
  position:relative;
  cursor:pointer;
  display: inline-flex;
  margin: -0.5rem 0rem 1.5rem 1.5rem;
}
#banner-img-wrapper > div {
  position:absolute;
  top: 100%;
  left: 82%;
  background:rgb(226, 95, 95);
  width:55px;
  height:55px;
  border-radius:50%;
  text-align: center;
  padding-top: 9px;
  margin-top: -63px;
  opacity:0;
  transform:scale(1);
  transition:all .5s linear;
  font-size:1.5em;
}
#banner-img-wrapper > img {
  box-shadow: 0 1px 10px rgba(0,0,0,0.4);
  width:inherit;
  height:inherit;
  object-fit: cover;
  border-radius: 15px;
}
#banner-img-wrapper:hover {
  background: #c0c0c0;
  opacity: 0.6;
}
#banner-img-wrapper:hover > div {
  opacity:1;
  transform:scale(1);
  color: white;
}

#screenshot-img-wrapper {
  width: 170px;
  height: 300px;
  position:relative;
  cursor:pointer;
  display: inline-flex;
  object-fit: cover;
  margin: -0.5rem 0rem 1.5rem 1.5rem;
}
#screenshot-img-wrapper > div {
  position:absolute;
  top: 100%;
  left: 63%;
  background:rgb(226, 95, 95);
  width:50px;
  height:50px;
  border-radius:50%;
  text-align: center;
  padding-top: 4px;
  margin-top: -63px;
  opacity:0;
  transform:scale(1);
  transition:all .5s linear;
  font-size:1.5em;
}
#screenshot-img-wrapper > img {
  box-shadow: 0 1px 10px rgba(0,0,0,0.4);
  width:inherit;
  height:inherit;
  object-fit: cover;
  border-radius: 15px;
}
#screenshot-img-wrapper:hover {
  background: #c0c0c0;
  opacity: 0.6;
}
#screenshot-img-wrapper:hover > div {
  opacity:1;
  transform:scale(1);
  color: white;
}

.error-modal {
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.error-modal .title {
  font-size: 16px;
  font-weight: bolder;
  height: 0px;
}
