.ql-container {
  box-sizing: border-box;
  font-family: Helvetica, Arial, sans-serif;
  font-size: 13px;
  height: 100%;
  margin: 0px;
  position: relative;
  border-radius: 0px 0px 10px 10px;
}

.ql-toolbar.ql-snow {
  border: 1px solid #ccc;
  box-sizing: border-box;
  font-family: 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  padding: 8px;
  border-radius: 10px 10px 0px 0px;
}

.post {
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 1.2rem;
  margin-bottom: 0.8rem;
}

.post__header {
  display: flex;
  align-items: center;
  padding: 1.00rem;
}

.post__image {
  width: 100%;
  object-fit: contain;
  border-top: 0.062rem solid lightgray;
  border-bottom: 0.062rem solid lightgray;
}

.post__text {
  font-weight: normal;
  font-size: 13px;
  color: #c43a3a;
}

.post__contents {
  min-height: 80px;
}

.post__body {
  padding: 0 1rem;
}

.post__commenter {
  font-weight: normal;
  font-size: 13px;
  color: #c43a3a;
}

.post__commentBoader {
  border-bottom: 0.062rem solid lightgray;
  display: flex;
  margin-bottom: 10px;
}

.post__commentDate {
  font-weight: normal;
  font-size: 11px;
  color: #868686;
}

section {
  border-top: 0.062rem solid lightgray;
  display: flex;
  flex-shrink: 0;
  align-items: stretch;
  margin-top: 0.25rem;
}

.post__buttonIcon {
  font-size: 0;
  padding: 0.5rem;
  background: 0 0;
  border: 0;
  cursor: pointer;
}

.iconview {
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconview__border {
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.062rem solid lightgray;
}

.iconlike {
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.iconlike__border {
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 0.062rem solid lightgray;
}

.iconsave {
  display: inline-block;
  margin-left: auto;
  margin-right: -0.625rem;
}

.iconshare {
  margin-left: -0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post__likeCount {
  margin-bottom: 0.625rem;
}

.post__comments {
  margin-top: 1.875rem;
  margin-bottom: 1.25rem;
}

.post__comments p {
  margin-top: 0.312rem;
  margin-bottom: 0.625rem;
}

.post__commentBox {
  border-top: 0.062rem solid lightgray;
  display: flex;
}

.post__input {
  flex: 1;
  border: none;
  padding: 0.625rem;
}

.post__button {
  flex: 0;
  border: none;
  color: #6082a3;
  background-color: transparent;
  margin: 0 0.25rem;
}

.body__content p {
  margin: 0
}