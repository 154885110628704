.boxs {
  margin: 1% 23px;
}
.box {
  width: 170px;
  min-width: 120px;
  background: #fff;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14),0 1px 10px 0 rgba(0,0,0,0.12),0 2px 4px -1px rgba(0,0,0,0.3);
  margin: 15px 10px 15px 10px;
  transition: background-color 250ms ease-out 100ms;
  font-family: Arial, sans-serif;
}
.box img {
  width: 130px;
  height: 130px;
  display: block;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 10px;
}
.box .content {
  padding: 10px;
}
.box p{
  margin: 7px 0;
}
.box .title {
  color: #444444;
  font-size: 18px;
  display: block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin: 0px;
}
.box .version {
  font-size: 80%;
  display: block;
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 52px;
}
.box .target {
  font-size: 80%;
  text-align: right;
}
.box .android {
  padding: 3px 0px 3px 0px;
  text-align: right;
  color: #25c2e3;
  background-color: #d3f3f9;
}
.box .ios {
  padding: 3px;
  color: #ff5c75;
  background-color: #ffdee3;
}
.box .webapp {
  padding: 3px;
  color: #43d39e;
  background-color: #d9f6ec;
}
.box .registerdate {
  font-size: 90%;
  text-align: right;
  display: block;
  text-overflow:ellipsis;
  overflow: hidden;
  white-space: nowrap;
  margin-top: 10px;
}
.box .download {
  font-size: 90%;
  display: block;
  text-align: right;
  margin-top: 5px;
  margin-bottom: 3px;
}
.box:hover {
  background: #e0e0e0;
  opacity: 0.9;
}